import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.medium};
  min-height: calc(100vh - 231px);

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }

  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
  }
`

export const Container = styled.div`
  min-height: calc(100vh - 231px);
  max-width: 794px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 40px 16px;

  h2 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 34px;
    margin-bottom: 0.5em;
    margin-top: 0;
  }

  h3 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    margin-bottom: 0.5em;
  }

  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 17px;
    letter-spacing: -0.24px;
    margin-top: 0;
    line-height: 1.4;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  ul {
    padding-inline-start: 16px;
  }

  li {
    padding-bottom: 16px;
    & p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: black;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
    padding: 50px 100px;
  }

  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
    padding: 50px 100px;
  }
`
